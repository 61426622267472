import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const FieldWithLabel = styled.div<{ isPredicted?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 15px 0 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${GetColor.Grey};
  }

  .qa-dropmenu-trigger {
    line-height: 14px;
    ${({ isPredicted }) =>
      isPredicted &&
      css`
        border-color: ${GetColor.HighlightDark};
        label {
          color: ${GetColor.HighlightDark};
        }
      `}
  }
`;
export default FieldWithLabel;
