/*
 * Venn Platform API 1.0.0 Operations for "printing"
 * Automatically generated file - do not modify!
 */

import type {
  BlockFontOverrides,
  LegalFontOverrides,
  LogoDetails,
  PrintSettings,
  PrintingRequest,
  SectionPageFontOverrides,
  TitlePageFontOverrides,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Delete specific organization print logo */
export async function deletePrintLogoById(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/printing/logo/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get PDF */
export async function getPDF(body?: Partial<PrintingRequest>, signal?: AbortSignal): Promise<OperationResult<Blob>> {
  const endpoint = '/api/printing/pdf';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Blob = (await response.blob()) as Blob;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all organization print logos */
export async function getPrintLogoIds(signal?: AbortSignal): Promise<OperationResult<LogoDetails[]>> {
  const endpoint = '/api/printing/logos';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: LogoDetails[] = (await response.json()) as LogoDetails[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get default print settings */
export async function getOrganizationPrintSettings(signal?: AbortSignal): Promise<OperationResult<PrintSettings>> {
  const endpoint = '/api/printing/settings';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PrintSettings = (await response.json()) as PrintSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get default printing logo */
export async function getDefaultPrintLogo(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/logo/default';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get printing logo */
export async function getPrintLogoById(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/printing/logo/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function patchPrintSettings(
  body?: Partial<PrintSettings>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings';
  const requestInit = buildRequestInit({
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function storeBlockFontOverrides(
  body?: Partial<BlockFontOverrides>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings/blockFontOverrides';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function storeLegalFontOverrides(
  body?: Partial<LegalFontOverrides>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings/legalFontOverrides';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function storeSectionPageFontOverrides(
  body?: Partial<SectionPageFontOverrides>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings/sectionPageFontOverrides';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function storeTitlePageFontOverrides(
  body?: Partial<TitlePageFontOverrides>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings/titlePageFontOverrides';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save default print settings */
export async function storeOrganizationPrintSettings(
  body?: Partial<PrintSettings>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/settings';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save default printing logo */
export async function storeDefaultPrintLogo(body?: any, signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/printing/logo/default';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save printing logo */
export async function storePrintLogo(body?: any, signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/printing/logo';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set logo as default */
export async function setPrintLogoAsDefaultById(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/printing/logo/default/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Unset logo as default */
export async function unsetDefaultPrintLogo(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/printing/logo/default';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
