/*
 * Venn Platform API 1.0.0 Operations for "holdings"
 * Automatically generated file - do not modify!
 */

import type { HoldingsBreakdownTypeEnum, HoldingsCategory, HoldingsDataRequest, HoldingsDataResponse } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get holdings breakdown */
export async function getHoldingsBreakdown(
  body?: Partial<HoldingsDataRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<HoldingsDataResponse>> {
  const endpoint = '/api/holdings';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: HoldingsDataResponse = (await response.json()) as HoldingsDataResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get holdings categories */
export async function getHoldingsCategories(
  breakdownType: HoldingsBreakdownTypeEnum,
  signal?: AbortSignal,
): Promise<OperationResult<HoldingsCategory[]>> {
  const endpoint = `/api/holdings/categories/${encodeURIComponent(breakdownType)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: HoldingsCategory[] = (await response.json()) as HoldingsCategory[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
