/*
 * Venn Platform API 1.0.0 Operations for "signup"
 * Automatically generated file - do not modify!
 */

import type {
  AccountCreationRequest,
  CompanyTypeOption,
  Country,
  InvitationDetails,
  Locality,
  ResponseBodyBoolean,
  SignupOptions,
  UserStatusEnum,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get a list of supported company types */
export async function getCompanyTypes(signal?: AbortSignal): Promise<OperationResult<CompanyTypeOption[]>> {
  const endpoint = '/api/signup/company-type';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CompanyTypeOption[] = (await response.json()) as CompanyTypeOption[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of supported countries */
export async function getSupportedCountries(signal?: AbortSignal): Promise<OperationResult<Country[]>> {
  const endpoint = '/api/signup/country';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Country[] = (await response.json()) as Country[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of supported country localities */
export async function getSupportedCountryLocalities(
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: Locality[] }>> {
  const endpoint = '/api/signup/locality';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: Locality[] } = (await response.json()) as { [key: string]: Locality[] };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all signup options */
export async function getSignupOptions(signal?: AbortSignal): Promise<OperationResult<SignupOptions>> {
  const endpoint = '/api/signup/options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: SignupOptions = (await response.json()) as SignupOptions;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieves the details associated with an invitation email and access code. */
export async function getInvitationDetails(
  email?: string,
  activationCode?: string,
  signal?: AbortSignal,
): Promise<OperationResult<InvitationDetails>> {
  const endpoint = `/api/signup/invitation/details${buildQuery({ email, activationCode })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: InvitationDetails = (await response.json()) as InvitationDetails;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Validate a new accounts activation and check that it is still valid and not an active user */
export async function validateAccountActivation(
  body?: Partial<AccountCreationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<UserStatusEnum>> {
  const endpoint = '/api/signup/activate/validate';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserStatusEnum = (await response.json()) as UserStatusEnum;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Validate if an activation code is valid for a given email, true if expired */
export async function checkActivationExpired(
  body?: Partial<AccountCreationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<ResponseBodyBoolean>> {
  const endpoint = '/api/signup/check-expired';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ResponseBodyBoolean = (await response.json()) as ResponseBodyBoolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
