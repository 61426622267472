/*
 * Venn Platform API 1.0.0 Operations for "portfolios"
 * Automatically generated file - do not modify!
 */

import type {
  Portfolio,
  PortfolioData,
  PortfolioPolicy,
  PortfolioPolicyCheckRequest,
  RecentPortfolioRange,
  RemoteTypeEnum,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Check a portfolio against a given portfolio policy, possibly against a baseline portfolio as well */
export async function checkPortfolioPolicy(
  body: Partial<PortfolioPolicyCheckRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<boolean[]>> {
  const endpoint = '/api/portfolios/policy/check';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: boolean[] = (await response.json()) as boolean[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Check if given portfolio name is already used for current permission group */
export async function checkPortfolioNameV3(name?: string, signal?: AbortSignal): Promise<OperationResult<boolean>> {
  const endpoint = `/api/portfolios/{apiVersion}/exists${buildQuery({ name })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Copy a portfolio to venn demo portfolio */
export async function createVennDemoPortfolio(id: number, signal?: AbortSignal): Promise<OperationResult<Portfolio>> {
  const endpoint = `/api/portfolios/{apiVersion}/venn/demo/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create a portfolio and return. */
export async function createPortfolioV3(
  body?: Partial<Portfolio>,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/create';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create from efficient frontier portfolio data */
export async function createPortfolioFromEfficientFrontierV3(
  body?: Partial<PortfolioData>,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/create-from-efficient-frontier';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create or update a portfolio from remote */
export async function createOrUpdatePortfolioV3FromRemote(
  remoteType: RemoteTypeEnum,
  sourceId: string,
  workspaceId?: number,
  body?: Partial<Portfolio>,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = `/api/portfolios/{apiVersion}/remote/${encodeURIComponent(remoteType)}/${encodeURIComponent(sourceId)}${buildQuery({ workspaceId })}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create or update a portfolio policy */
export async function setPortfolioPolicy(
  body?: Partial<PortfolioPolicy>,
  signal?: AbortSignal,
): Promise<OperationResult<string>> {
  const endpoint = '/api/portfolios/policy';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a collection of portfolios */
export async function deletePortfoliosV3(body?: number[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/portfolios/{apiVersion}';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a specific portfolio */
export async function deletePortfolioV3(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a specific portfolio policy by policyId */
export async function deletePortfolioPolicy(policyId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/portfolios/policy/${encodeURIComponent(policyId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete portfolios by remote ids */
export async function deletePortfoliosByRemoteIds(
  remoteType: RemoteTypeEnum,
  workspaceId?: number,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<string[]>> {
  const endpoint = `/api/portfolios/{apiVersion}/remote/${encodeURIComponent(remoteType)}${buildQuery({ workspaceId })}`;
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of portfolio versions */
export async function getPortfolioVersions(id: number, signal?: AbortSignal): Promise<OperationResult<Portfolio[]>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}/versions`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio[] = (await response.json()) as Portfolio[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a specific portfolio */
export async function getSpecificPortfolioV3(
  id: number,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the demo portfolio for the workspace. Return master if demo is not available */
export async function getDemoPortfolio(signal?: AbortSignal): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/demo';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the master portfolio for an workspace */
export async function getMasterPortfolioV3(signal?: AbortSignal): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/master';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the master portfolio id and recent range for the portfolio analysis */
export async function getRecentMasterPortfolioRange(
  signal?: AbortSignal,
): Promise<OperationResult<RecentPortfolioRange>> {
  const endpoint = '/api/portfolios/{apiVersion}/master/recent';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: RecentPortfolioRange = (await response.json()) as RecentPortfolioRange;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the portfolio policy for a given portfolioId */
export async function getPortfolioPolicyByPortfolioId(
  portfolioId: number,
  signal?: AbortSignal,
): Promise<OperationResult<PortfolioPolicy>> {
  const endpoint = `/api/portfolios/policy/portfolio/${encodeURIComponent(portfolioId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: PortfolioPolicy = (await response.json()) as PortfolioPolicy;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the saved demo portfolio for the workspace. Create one if not available. */
export async function getSavedDemoPortfolio(signal?: AbortSignal): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/saved/demo';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the venn demo portfolio */
export async function getVennDemoPortfolio(signal?: AbortSignal): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/venn/demo';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set a portfolio as the master portfolio for an workspace */
export async function setAsMasterPortfolioV3(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}/master`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set a portfolio as the master portfolio for an workspace. Prefer PUT /portfolios/v3/:id/master */
export async function setAsOfficialPortfolioV3(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}/official`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set portfolio as the master portfolio for an workspace */
export async function createPortfolioAsMasterV3(
  body?: Partial<Portfolio>,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = '/api/portfolios/{apiVersion}/master';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set up relationships and entities for a new workspace */
export async function setupPortfolioAPIWorkspace(
  workspaceId: number,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/portfolios/setup/${encodeURIComponent(workspaceId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update a portfolio and make it non-draft */
export async function updatePortfolioV3(
  id: number,
  body?: Partial<Portfolio>,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update a portfolios name in place */
export async function updatePortfolioName(
  id: number,
  body?: string,
  signal?: AbortSignal,
): Promise<OperationResult<Portfolio>> {
  const endpoint = `/api/portfolios/{apiVersion}/${encodeURIComponent(id)}/name`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Portfolio = (await response.json()) as Portfolio;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
