import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import BannerMessage from '../../banner-message/BannerMessage';
import { ModalSize } from '../../modal/components/ModalWrapper';
import Modal from '../../modal/Modal';
import ModalFooter from '../../modal/ModalFooter';

export type DataModificationsCategory = {
  title: string;
  message: string;
  footerMessage: string;
  entities: string[];
};
type DataModificationsModalProps = {
  onClose: () => void;
  modifications: DataModificationsCategory[];
};

type DataModificationsContentProps = {
  modifications: DataModificationsCategory[];
  isReportDisclosure: boolean;
};

export const DataModificationsContent = ({ modifications, isReportDisclosure }: DataModificationsContentProps) => {
  if (isEmpty(modifications)) {
    return null;
  }
  return (
    <DataModificationsContentWrapper isReportDisclosure={isReportDisclosure}>
      {modifications.map((modification) => (
        <SingleModificationWrapper key={modification.title}>
          <div>
            {!isReportDisclosure && <DataModificationCategoryTitle>{modification.title}</DataModificationCategoryTitle>}
            <div>{modification.message}</div>
          </div>
          <ul>
            {modification.entities.map((entity, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{entity}</li>
            ))}
          </ul>
          {!isReportDisclosure && <div>{modification.footerMessage}</div>}
        </SingleModificationWrapper>
      ))}
    </DataModificationsContentWrapper>
  );
};
export const DataModificationsModal = ({ onClose, modifications }: DataModificationsModalProps) => {
  const { Colors } = useContext(ThemeContext);

  if (isEmpty(modifications)) {
    return null;
  }
  return (
    <Modal size={ModalSize.Large} onClose={onClose} noPadding closeOnEsc closeOnOutsideClick>
      <DataModificationsModalWrapper className="data-modifications-modal-wrapper">
        <BannerMessage backgroundColor={Colors.Primary.Main} errorType="ok" title="Contains Venn-Modified Data" small />
        <DataModificationsContent isReportDisclosure={false} modifications={modifications} />
        <ModalFooter primaryLabel="Close" onPrimaryClick={onClose} primaryDisabled={false} />
      </DataModificationsModalWrapper>
    </Modal>
  );
};

const SingleModificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
`;

const DataModificationsContentWrapper = styled.div<{ isReportDisclosure: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ isReportDisclosure }) => (isReportDisclosure ? '0' : '24px 24px 0 24px')};
  gap: ${({ isReportDisclosure }) => (isReportDisclosure ? '0' : '24px')};

  ${({ isReportDisclosure }) =>
    isReportDisclosure &&
    css`
      & ${SingleModificationWrapper} {
        gap: 0;
      }
    `}
`;

const DataModificationsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DataModificationCategoryTitle = styled.span`
  font-weight: bold;
`;
