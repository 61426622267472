import { QueryClient } from '@tanstack/react-query';

/**
 * This globally defines the QueryClient for react-query.
 * It will allow us to use this client outside of components,
 * for example in recoil. It implements a singleton pattern.
 */
export const VennQueryClient = (() => {
  let instance: QueryClient | undefined;
  const createInstance = (): QueryClient => {
    instance = new QueryClient({
      defaultOptions: {
        queries: {
          /** Retries are performed automatically by any autogenerated API, so we turn them off in the query client. */
          retry: false,
          refetchOnWindowFocus: false,
          cacheTime: 24 * 60 * 60 * 1000, // 24h
        },
      },
    });
    return instance;
  };
  return {
    getInstance: () => instance ?? createInstance(),
  };
})();
