import React, { Fragment } from 'react';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, Tooltip, CellLoader, getAppTitle } from 'venn-ui-kit';
import MetaHeader from './MetaHeader';
import FieldWithLabel from './investment/components/FieldWithLabel';

interface SelectorRowProps<TOptionValue> {
  label: string;
  subLabel?: React.ReactNode;
  value?: TOptionValue;
  options: DropMenuItem<TOptionValue>[];
  isEditable: boolean;
  menuWidth?: number;
  disabledMessage?: string;
  info?: string;
  link?: string;
  onSelect: (selectedOption: DropMenuItem<TOptionValue>) => void;
  className?: string;
  isPredicted?: boolean;
  showDisabledStyling: boolean;
  loading?: boolean;
  labelRightContent?: React.ReactNode;
  disabledTooltip?: string;
}

function SelectorRow<TOptionValue>({
  label,
  value,
  options,
  isEditable,
  menuWidth,
  disabledMessage,
  onSelect,
  className,
  info,
  link,
  isPredicted,
  showDisabledStyling,
  loading,
  labelRightContent,
  disabledTooltip,
  subLabel,
}: SelectorRowProps<TOptionValue>) {
  const Wrapper = disabledMessage || isPredicted ? Tooltip : Fragment;
  const wrapperProps = disabledMessage
    ? { content: disabledMessage }
    : isPredicted
      ? { content: `This field was populated by ${getAppTitle()}.` }
      : {};

  const selectedOption = options.find((option) => option.value === value);
  const selectedOptionName = selectedOption ? selectedOption.label : '--';
  return (
    <FieldWithLabel className={className} isPredicted={isPredicted}>
      <MetaHeader
        label={label}
        subLabel={subLabel}
        info={info}
        link={link}
        rightContent={labelRightContent}
        disabledTooltip={showDisabledStyling ? disabledTooltip : undefined}
      />
      {loading ? (
        <CellLoader className="qa-loading" />
      ) : isEditable ? (
        <Wrapper {...wrapperProps}>
          <DropMenu<TOptionValue>
            items={options}
            selected={value}
            onChange={onSelect}
            searchable
            disabled={!isEditable || !options || options.length === 0 || !!disabledMessage}
            style={{ width: menuWidth }}
            disableTooltip={isPredicted}
          />
        </Wrapper>
      ) : (
        <div>{selectedOptionName}</div>
      )}
    </FieldWithLabel>
  );
}

export default SelectorRow;
