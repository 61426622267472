/*
 * Venn Platform API 1.0.0 Operations for "interpolation"
 * Automatically generated file - do not modify!
 */

import type { ProxyCategoryEnum, ProxyCategoryMapping, ProxyOption } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get all interpolation categories */
export async function getInterpolationCategories(signal?: AbortSignal): Promise<OperationResult<ProxyCategoryEnum[]>> {
  const endpoint = '/api/interpolation/categories';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyCategoryEnum[] = (await response.json()) as ProxyCategoryEnum[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return interpolated option of custom proxy for fund */
export async function getCustomInterpolationOptionForFund(
  fundId: string,
  proxyId: string,
  signal?: AbortSignal,
): Promise<OperationResult<ProxyOption>> {
  const endpoint = `/api/interpolation/fund/${encodeURIComponent(fundId)}/custom/${encodeURIComponent(proxyId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyOption = (await response.json()) as ProxyOption;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return list of interpolation options, some of which are disabled */
export async function getInterpolationOptionsForFund(
  fundId: string,
  signal?: AbortSignal,
): Promise<OperationResult<ProxyOption[]>> {
  const endpoint = `/api/interpolation/fund/${encodeURIComponent(fundId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyOption[] = (await response.json()) as ProxyOption[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set fund for interpolation category */
export async function saveFundForCategory(
  category: ProxyCategoryEnum,
  fundId: string,
  signal?: AbortSignal,
): Promise<OperationResult<ProxyCategoryMapping>> {
  const endpoint = `/api/interpolation/${encodeURIComponent(category)}/${encodeURIComponent(fundId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ProxyCategoryMapping = (await response.json()) as ProxyCategoryMapping;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
