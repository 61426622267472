/*
 * Venn Platform API 1.0.0 Operations for "blogposts"
 * Automatically generated file - do not modify!
 */

import type { BlogPost } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Query HubSpot for the latest blog post */
export async function getLatestBlogPost(signal?: AbortSignal): Promise<OperationResult<BlogPost>> {
  const endpoint = '/api/blogposts/latest';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: BlogPost = (await response.json()) as BlogPost;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
