/*
 * Venn Platform API 1.0.0 Operations for "org"
 * Automatically generated file - do not modify!
 */

import type {
  AppUser,
  InternalWorkspaceInvitation,
  InviteValidationResponse,
  TSPrivateAccountRequest,
  TableWorkspace,
  UserWorkspace,
  WorkspaceCreationRequest,
  WorkspaceMemberQuery,
  WorkspaceMemberResults,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Add a user to one of my workspaces */
export async function addUserToMyWorkspace(
  workspaceId: number,
  userId: number,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/org/${encodeURIComponent(workspaceId)}/members/${encodeURIComponent(userId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create invitations for a workspace and return the JSON representations. Does not send the invite emails */
export async function createInvitations(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<InternalWorkspaceInvitation[]>> {
  const endpoint = '/api/org/invite/create';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: InternalWorkspaceInvitation[] = (await response.json()) as InternalWorkspaceInvitation[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create private two sigma account */
export async function createTwoSigmaAccount(
  body?: Partial<TSPrivateAccountRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<TableWorkspace>> {
  const endpoint = '/api/org/tsprivate';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TableWorkspace = (await response.json()) as TableWorkspace;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all members of my workspace */
export async function getAllWorkspaceMembers(signal?: AbortSignal): Promise<OperationResult<AppUser[]>> {
  const endpoint = '/api/org/members';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AppUser[] = (await response.json()) as AppUser[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all private account requests */
export async function getAllAccountRequests(signal?: AbortSignal): Promise<OperationResult<TSPrivateAccountRequest[]>> {
  const endpoint = '/api/org/tsprivate/request';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: TSPrivateAccountRequest[] = (await response.json()) as TSPrivateAccountRequest[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get current users workspace */
export async function getMyWorkspace(signal?: AbortSignal): Promise<OperationResult<UserWorkspace>> {
  const endpoint = '/api/org/me';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: UserWorkspace = (await response.json()) as UserWorkspace;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get invitations for workspace */
export async function getWorkspaceInvitations(
  signal?: AbortSignal,
): Promise<OperationResult<InternalWorkspaceInvitation[]>> {
  const endpoint = '/api/org/invite';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: InternalWorkspaceInvitation[] = (await response.json()) as InternalWorkspaceInvitation[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Reject TS private account request */
export async function rejectAccountCreation(email: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/org/tsprivate/${encodeURIComponent(email)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a user from my current workspace */
export async function removeUserFromMyWorkspace(userId: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/org/members/${encodeURIComponent(userId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove expired invitations for a list of emails for the current workspace */
export async function removeInvitations(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/org/invite/remove';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Revoke invitations to a list of emails for the current workspace */
export async function revokeInvitations(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/org/invite/revoke';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search all members of my workspace */
export async function searchOrganizationMembers(
  body?: Partial<WorkspaceMemberQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<WorkspaceMemberResults>> {
  const endpoint = '/api/org/members';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: WorkspaceMemberResults = (await response.json()) as WorkspaceMemberResults;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Send invitations for workspace */
export async function adminInviteUsersToWorkspace(
  workspaceId: number,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<InternalWorkspaceInvitation[]>> {
  const endpoint = `/api/org/invite/${encodeURIComponent(workspaceId)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: InternalWorkspaceInvitation[] = (await response.json()) as InternalWorkspaceInvitation[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Send invitations for workspace */
export async function sendInvitations(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<InternalWorkspaceInvitation[]>> {
  const endpoint = '/api/org/invite';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: InternalWorkspaceInvitation[] = (await response.json()) as InternalWorkspaceInvitation[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Setup a new workspace */
export async function setupNewWorkspace(
  body?: Partial<WorkspaceCreationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/org/setup';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Validate invitations for a collection of emails */
export async function validateInvitations(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<InviteValidationResponse>> {
  const endpoint = '/api/org/invite/validate';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: InviteValidationResponse = (await response.json()) as InviteValidationResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
