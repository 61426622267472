import { isNil } from 'lodash';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { css, default as styled } from 'styled-components';
import type { ComputedInvestmentResidual } from 'venn-api';
import {
  forecastPanelViewSelector,
  ForecastTab,
  investmentOverrideSearchQuery,
  investmentOverrideShouldScrollToAddForm,
  selectedInvestmentOverrideAtomSyncedWithOverrideType,
} from 'venn-state';
import { GetColor, Hint, Icon, Label } from 'venn-ui-kit';
import { buttonize, useHasFF } from 'venn-utils';
import LongTermForecastTooltip from '../../../../venn-frontend/src/analysis-page/src/components/performance-summary/LongTermForecastTooltip';
import InvestmentOverridesContainerFooter, {
  makeDefaultInvestmentDataFromForecast,
} from '../../modals/forecasts/components/InvestmentOverridesContainerFooter';
import type { MenuOpenerProps } from '../../modals/forecasts/ForecastsMenuLauncher';
import ForecastsMenuLauncher from '../../modals/forecasts/ForecastsMenuLauncher';
import MetaHeader from '../MetaHeader';
import FieldWithLabel from './components/FieldWithLabel';
import { FieldWithLabelHeader, FieldWithLabelHeaderLeft } from './components/styled';

interface ResidualForecastRowProps {
  canEditForecasts: boolean;
  onResidualForecastUpdated: (targetId?: string) => void;
  residualForecast: ComputedInvestmentResidual;
}

const EditForecastLink = ({ onClick, openResidualForecastTarget, isReadOnly }: MenuOpenerProps) => {
  return (
    <ForecastLink onClick={onClick} openResidualForecastTarget={openResidualForecastTarget} isReadOnly={isReadOnly}>
      Edit Forecasts <EditIcon type="compass" />
    </ForecastLink>
  );
};

const ForecastLink = ({
  onClick,
  openResidualForecastTarget,
  isReadOnly,
  children,
}: PropsWithChildren<MenuOpenerProps>) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');
  const operation = hasRedesignFF ? (openResidualForecastTarget?.isManualOverride ? 'EDIT' : 'CREATE') : 'EDIT';

  const setForecastPanelView = useSetRecoilState(forecastPanelViewSelector);
  const setInvestmentOverride = useSetRecoilState(selectedInvestmentOverrideAtomSyncedWithOverrideType(operation));
  const setSearchQuery = useSetRecoilState(investmentOverrideSearchQuery);
  const setShouldScrollToAddForm = useSetRecoilState(investmentOverrideShouldScrollToAddForm);

  return (
    <LinkStyle
      {...buttonize(() => {
        setForecastPanelView({
          tab: ForecastTab.InvestmentForecast,
          detail: {
            type: hasRedesignFF ? 'Empty' : 'InvestmentOverrideEditor',
          },
        });
        if (!isReadOnly) {
          setInvestmentOverride?.(openResidualForecastTarget);
          hasRedesignFF && operation === 'EDIT' && setSearchQuery(openResidualForecastTarget?.fundName ?? '');
          hasRedesignFF && operation === 'CREATE' && setShouldScrollToAddForm(true);
        }
        onClick();
      })}
      className="qa-manage-forecasts-btn"
    >
      {children}
    </LinkStyle>
  );
};

// Row with in-line buttons to edit the investment's residual forecast.
const ResidualForecastRow = ({
  canEditForecasts,
  onResidualForecastUpdated,
  residualForecast,
}: ResidualForecastRowProps) => {
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const header = (
    <FieldWithLabelHeader>
      <FieldWithLabelHeaderLeft>
        <MetaHeader label="Investment Forecast" />
      </FieldWithLabelHeaderLeft>
      <Label>
        <ForecastsMenuLauncher
          onResidualForecastUpdated={onResidualForecastUpdated}
          onOpenResidualForecastTarget={residualForecast}
          ctaPurpose="Edit forecasts from Investment information page"
          renderCustomMenuOpener={(props) => <EditForecastLink {...props} />}
          isReadOnly={!canEditForecasts}
        />
      </Label>
    </FieldWithLabelHeader>
  );
  const hint = (
    <Hint>
      Investment forecast is part of your long-term forecasts.
      <LongTermForecastTooltip />
    </Hint>
  );
  const isReturnOverride = !isNil(residualForecast.overriddenReturn);
  const isResidualOverride = !isNil(residualForecast.overriddenResidual);
  const investmentData =
    isResidualOverride || isReturnOverride
      ? makeDefaultInvestmentDataFromForecast(residualForecast, hasRedesignFF)
      : [
          { datum: residualForecast.defaultReturn, copy: 'Default Total Return', shouldHighlight: false },
          { datum: residualForecast.defaultResidual, copy: 'Default Residual', shouldHighlight: false },
        ];
  const forecastData = (
    <ResidualForecastTexWrapper margin={26}>
      <InvestmentOverridesContainerFooter investmentData={investmentData} />
    </ResidualForecastTexWrapper>
  );
  return (
    <StyledFieldWithLabel showWhiteBackground={false}>
      {header}
      {hint}
      {forecastData}
    </StyledFieldWithLabel>
  );
};

export default React.memo(ResidualForecastRow);

const StyledFieldWithLabel = styled(FieldWithLabel)<{ showWhiteBackground: boolean }>`
  ${({ showWhiteBackground }) =>
    showWhiteBackground &&
    css`
      background-color: ${GetColor.White};
    `}
  & > span {
    display: flex;
  }
`;

const ResidualForecastTexWrapper = styled.span<{ margin?: number }>`
  ${({ margin }) => css`
    margin-top: ${margin ?? 10}px;
  `}
`;

const LinkStyle = styled.a`
  margin-left: 4px;
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
`;

const EditIcon = styled(Icon)`
  margin-left: 5px;
`;
