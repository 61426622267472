import type { CSSProperties } from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ZIndex } from '../../../zIndexValues';
import type { DropMenuItem } from '../types';
import LegacyRelativePortal from '../../relative-portal/LegacyRelativePortal';
import { ExternalActivityListener } from '../../external-activity-listener';
import { analyticsService, type DropdownOpened } from 'venn-utils';

export const portalMenuIgnoreActivityClassName = 'portal-menu-ignore-activity';

export type PortalAnchorPlacement = 'left' | 'right';

export interface DropMenuProps<T> {
  className?: string;
  /**
   * If true, the menu will be opened by default, on mount.
   */
  openByDefault?: boolean;
  /**
   * If true, the menu will be moved into a React Portal. Use this if you want to render the dropdown
   * within a modal
   */
  usePortal?: boolean;
  /**
   Specify placement of portal's anchor
   */
  portalAnchorPlacement?: PortalAnchorPlacement;
  /**
   * Provides the menu component.
   */
  menuComponent: (
    /** The selected item. */
    highlighted: DropMenuItem<T> | undefined,
    /** Invoke to force the drop menu to close. */
    forceCollapse: () => void,
    /**
     * When used as a Portal, this className must be added to the top level of your component to prevent collapsing prematurely.
     * Without this className, clicking inside your component may be detected as an outside click.
     */
    className?: string,
  ) => JSX.Element;
  /**
   * Provides the trigger component
   */
  triggerComponent: (
    expanded: boolean,
    highlighted: DropMenuItem<T> | undefined,
    toggleMenu: (open?: boolean) => void,
    handleKeyEsc: () => void,
    handleKeyEnter: () => void,
    handleKeyUp: () => void,
    handleKeyDown: () => void,
  ) => JSX.Element;
  /**
   * Filtered Items
   */
  filteredItems: DropMenuItem<T>[];
  /**
   * Selected item
   */
  selectedItem?: DropMenuItem<T>;
  /**
   * On selection, returns the selected item
   */
  onChange?: (item: DropMenuItem<T>) => void;
  style?: CSSProperties;
  /**
   * A custom function to run when the menu collapses
   */
  onCollapse?: () => void;
  /**
   * A custom function to run when the menu opens
   */
  onOpen?: () => void;
  /**
   * Label for the dropdown analytics
   */
  label?: string;
  /**
   * A flag for placing the menu above the trigger
   */
  menuAbove?: boolean;
  /**
   * Test ID for locating dropmenu in unit and E2E tests
   */
  'data-testid'?: string;
  /**
   * Tracking event fields to use.
   * If unspecified, tracking events are filled with simple dropdown information.
   * (useful when more specific tracking events are needed).
   */
  analyticsProps?: DropdownOpened;
}

function DropMenu<T>({
  triggerComponent,
  menuComponent,
  usePortal,
  openByDefault,
  className,
  filteredItems,
  selectedItem,
  onChange,
  style,
  onCollapse,
  onOpen,
  label,
  menuAbove,
  portalAnchorPlacement = 'left',
  'data-testid': dataTestId,
  analyticsProps,
}: DropMenuProps<T>) {
  const [expanded, setExpanded] = useState(openByDefault || false);
  const [highlighted, setHighlighted] = useState<DropMenuItem<T> | undefined>(selectedItem);

  const handleCollapse = useCallback(() => {
    setExpanded(false);
    setHighlighted(undefined);
    onCollapse?.();
    analyticsService.dropdownClosed(
      analyticsProps ?? {
        label,
        type: 'primary dropdown',
        selectType: 'single-select',
        selection: selectedItem?.label,
      },
    );
  }, [onCollapse, selectedItem, label, analyticsProps]);

  const handleToggle = useCallback(
    (open?: boolean) => {
      const toggleValue = open !== undefined ? open : !expanded;
      setExpanded(toggleValue);
      const finalAnalyticsProps = analyticsProps ?? {
        label,
        type: 'primary dropdown',
        selectType: 'single-select',
        selection: selectedItem?.label,
      };
      if (!toggleValue) {
        onCollapse?.();
        analyticsService.dropdownClosed(finalAnalyticsProps);
      } else {
        onOpen?.();
        analyticsService.dropdownOpened(finalAnalyticsProps);
      }
    },
    [expanded, onCollapse, label, selectedItem, onOpen, analyticsProps],
  );

  useEffect(() => {
    if (openByDefault) {
      setExpanded(true);
    }
  }, [openByDefault]);

  // Ensure that the highlighted item is reset to the selected item when it changes
  useEffect(() => {
    setHighlighted(selectedItem);
  }, [selectedItem]);

  const handleKeyEsc = useCallback(() => {
    if (expanded) {
      handleToggle(false);
    }
  }, [handleToggle, expanded]);

  const handleKeyEnter = useCallback(() => {
    handleToggle();
    if (highlighted) {
      onChange?.(highlighted);
    }
  }, [handleToggle, highlighted, onChange]);

  const handleKeyDown = useCallback(() => {
    if (!expanded) {
      handleToggle(true);
      setHighlighted(selectedItem);
    } else if (filteredItems.length > 0) {
      if (!highlighted) {
        setHighlighted(filteredItems[0]);
      } else {
        const selectedIndex = filteredItems.indexOf(highlighted);
        if (filteredItems.length > selectedIndex + 1) {
          setHighlighted(filteredItems[selectedIndex + 1]);
        }
      }
    }
  }, [filteredItems, expanded, handleToggle, highlighted, selectedItem]);

  const handleKeyUp = useCallback(() => {
    if (expanded) {
      if (!highlighted) {
        setHighlighted(filteredItems[0]);
      } else {
        const selectedIndex = filteredItems.indexOf(highlighted);
        if (selectedIndex !== 0) {
          setHighlighted(filteredItems[selectedIndex - 1]);
        } else {
          handleToggle(false);
        }
      }
    }
  }, [filteredItems, expanded, handleToggle, highlighted]);

  const portalClassName = usePortal ? portalMenuIgnoreActivityClassName : undefined;

  const menuInner = menuComponent(highlighted, handleCollapse, portalMenuIgnoreActivityClassName);

  const trigger = triggerComponent(
    expanded,
    highlighted,
    handleToggle,
    handleKeyEsc,
    handleKeyEnter,
    handleKeyUp,
    handleKeyDown,
  );

  return (
    <Container data-testid={dataTestId}>
      <Inner>
        <ExternalActivityListener
          onExternalActivity={handleCollapse}
          listeningEnabled={expanded}
          ignoreActivityFromClassName={portalClassName}
        >
          {!menuAbove && trigger}
          {expanded && (
            <MenuOuterOuter>
              {usePortal ? (
                <PortalAnchor placement={portalAnchorPlacement}>
                  <LegacyRelativePortal
                    right={portalAnchorPlacement === 'right' ? 0 : undefined}
                    className={className}
                    style={style}
                  >
                    {menuInner}
                  </LegacyRelativePortal>
                </PortalAnchor>
              ) : (
                <MenuContainer className={className} style={style} menuAbove={menuAbove}>
                  {menuInner}
                </MenuContainer>
              )}
            </MenuOuterOuter>
          )}
          {menuAbove && trigger}
        </ExternalActivityListener>
      </Inner>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div<{ menuAbove?: boolean }>`
  position: ${(props) => (props.menuAbove ? 'relative' : 'absolute')};
  z-index: ${ZIndex.Sticky};
  width: 100%;
  ${(props) =>
    props.menuAbove &&
    css`
      margin-bottom: 10px;
    `}
`;

const MenuOuterOuter = styled.div``;

const PortalAnchor = styled.div<{ placement: PortalAnchorPlacement }>`
  display: flex;
  justify-content: ${({ placement }) => (placement === 'left' ? 'flex-start' : 'flex-end')};
`;

export default DropMenu;
