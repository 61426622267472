import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import { ColorUtils, FilterDropMenu, GetColor, Icon } from 'venn-ui-kit';
import type { ReorderableListProps } from '../reorderable-list/ReorderableList';
import { ReorderableList } from '../reorderable-list';
import type { InfoGraphicTypeEnum } from 'venn-api';
import { flatten, isEmpty, uniqBy } from 'lodash';
import { blockSettings, type MetricSpecificError, useMetricsErrors } from 'venn-state';
import { assertNotNil, type Metric } from 'venn-utils';
import ConfirmationModal from '../modals/confirmation/ConfirmationModal';
import { useRecoilValue } from 'recoil';

export type MetricWithError = Metric & { errorMessage: MetricSpecificError };
interface SelectableMetricsGroupProps extends Omit<ReorderableListProps, 'reorderableItems' | 'onReorderItems'> {
  selectedBlockId: string;
  items: DropMenuCheckboxItem<string>[];
  reorderableItemGroups: Metric[][];
  onReorderItemsInGroups: (newItemGroups: Metric[][]) => void;
  reorderableItemRenderer?: (metric: MetricWithError) => JSX.Element;
  selectedMetricText?: string;
  className?: string;
  onChangeFilter: (items: DropMenuCheckboxItem<string>[]) => void;
  infographicType?: InfoGraphicTypeEnum;
  disabled?: boolean;
}

const SelectableMetricsGroup = ({
  selectedBlockId,
  items,
  selectedMetricText,
  reorderableItemGroups,
  reorderableItemRenderer,
  className,
  onChangeFilter,
  onReorderItemsInGroups,
  disabled,
}: SelectableMetricsGroupProps) => {
  const { metricErrors } = useMetricsErrors(selectedBlockId);
  const settings = useRecoilValue(blockSettings(selectedBlockId));
  const reorderableMetricsWithErrors: MetricWithError[][] = useMemo(() => {
    return reorderableItemGroups.map((reorderableGroup) => {
      return reorderableGroup.map((item) => {
        return {
          ...item,
          errorMessage: metricErrors[item.key]?.[0] ?? undefined, // first error message for now
        };
      });
    });
  }, [reorderableItemGroups, metricErrors]);
  const uniqueErrorReasons = useMemo(() => {
    return uniqBy(
      flatten(reorderableMetricsWithErrors)
        .map((item) => {
          return {
            key: item.key,
            error: item.errorMessage?.error,
          };
        })
        .filter((item) => item.error)
        .concat(
          !isEmpty(metricErrors?.blockLevel)
            ? [
                {
                  key: 'blockLevel',
                  error: metricErrors.blockLevel[0].error,
                },
              ]
            : [],
        ),
      'error',
    );
  }, [reorderableMetricsWithErrors, metricErrors]);
  const onReorderItems = useCallback(
    (reorderedItems: Metric[], reorderedListIdx: number) =>
      onReorderItemsInGroups([
        ...reorderableItemGroups.slice(0, reorderedListIdx),
        reorderedItems,
        ...reorderableItemGroups.slice(reorderedListIdx + 1),
      ]),
    [reorderableItemGroups, onReorderItemsInGroups],
  );

  const [deletionModalState, setDeletionModalState] = useState<Readonly<{
    deletedItem: Metric;
    groupIndex: number;
  }> | null>(null);

  const onDeleteItem = useCallback(
    (deletedItemKey, groupIndex) => {
      const newItems = reorderableMetricsWithErrors[groupIndex].filter((item) => item.key !== deletedItemKey);
      onReorderItems(newItems, groupIndex);
    },
    [reorderableMetricsWithErrors, onReorderItems],
  );

  return (
    <>
      {deletionModalState && (
        <ConfirmationModal
          destructive
          header="Are you sure you want to delete your Custom Field?"
          subhead="Deleting this field will permanently remove all of the entered information"
          onProceed={() => {
            onDeleteItem(deletionModalState.deletedItem.key, deletionModalState.groupIndex);
            setDeletionModalState(null);
          }}
          onCancel={() => {
            setDeletionModalState(null);
          }}
          cancelLabel="Cancel"
          proceedLabel="DELETE CUSTOM FIELD"
        />
      )}
      <div>
        <FilterDropMenu
          onChange={onChangeFilter}
          width={256}
          items={items}
          selectedMetricText={selectedMetricText ?? 'Metric'}
          className={className}
          disabled={disabled}
          location={`${settings.title} Block Settings`}
          tooltipPortal
        />
        <MetricWrapper>
          {reorderableMetricsWithErrors.map((reorderableItems, idx) => (
            <React.Fragment key={reorderableItems.map((i) => i.key).join('')}>
              <ReorderableList
                margin={20}
                reorderableItems={reorderableItems}
                itemRenderer={reorderableItemRenderer}
                onReorderItems={(newItems) => onReorderItems(newItems, idx)}
                disabled={disabled}
                onDeleteItem={(deletedItemKey) => {
                  const deletedItem = reorderableItems.find((item) => item.key === deletedItemKey);
                  if (deletedItem?.editable) {
                    setDeletionModalState({ deletedItem, groupIndex: idx });
                    return;
                  }
                  onDeleteItem(deletedItemKey, idx);
                }}
              />
              {idx < reorderableItemGroups.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        </MetricWrapper>
        {uniqueErrorReasons.map((error) => {
          if (!error) {
            return null;
          }
          return (
            <MetricErrorContainer data-testid="qa-metric-error-container" key={error.key}>
              <StyledIcon prefix="far" type="exclamation-circle" />
              {assertNotNil(error.error)}
            </MetricErrorContainer>
          );
        })}
      </div>
    </>
  );
};

export default SelectableMetricsGroup;

const StyledIcon = styled(Icon)`
  color: ${GetColor.Error};
  font-size: 12px;
`;

const MetricErrorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 6px 12px;
  margin-bottom: 6px;
  color: ${GetColor.DarkGrey2};
  border: 1px solid ${GetColor.Error};
  background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
`;

const MetricWrapper = styled.div`
  padding: 10px;
`;

const Separator = styled.div`
  margin: 10px;
  border-bottom: 1px solid ${GetColor.Grey};
`;
