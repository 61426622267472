/*
 * Venn Platform API 1.0.0 Operations for "subscriptions"
 * Automatically generated file - do not modify!
 */

import type { PublishingSubscriptionStatusEnum, Subscription } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Create subscription for account by email */
export async function publisherInviteByEmail(body?: string, signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/subscriptions/publisher/email-invitation';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body,
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all subscriptions and expirations as a user */
export async function getSubscriptionExpirationsAsSubscriber(
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: number }>> {
  const endpoint = '/api/subscriptions/subscriber/expirations';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: number } = (await response.json()) as { [key: string]: number };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get subscriptions as a user */
export async function getSubscriptionsForSubscriber(signal?: AbortSignal): Promise<OperationResult<Subscription[]>> {
  const endpoint = '/api/subscriptions/subscriber';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Subscription[] = (await response.json()) as Subscription[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get subscriptions for a publisher */
export async function getSubscriptionsForPublisher(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<Subscription[]>> {
  const endpoint = '/api/subscriptions/publisher';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: Subscription[] = (await response.json()) as Subscription[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set a subscription status as a publisher */
export async function setSubscriptionStatusAsPublisher(
  subscriptionId: string,
  body?: PublishingSubscriptionStatusEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/subscriptions/${encodeURIComponent(subscriptionId)}/publisher/status`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set a subscription status as a subscriber */
export async function setSubscriptionStatusAsSubscriber(
  subscriptionId: string,
  body?: PublishingSubscriptionStatusEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/subscriptions/${encodeURIComponent(subscriptionId)}/subscriber/status`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
