/*
 * Venn Platform API 1.0.0 Operations for "dashboard"
 * Automatically generated file - do not modify!
 */

import type { RecentPortfolioFactorHistory, RecentUserActivity } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Get recent activity */
export async function getUserActivity(signal?: AbortSignal): Promise<OperationResult<RecentUserActivity>> {
  const endpoint = '/api/dashboard/activity';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: RecentUserActivity = (await response.json()) as RecentUserActivity;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get recent factor performance */
export async function getRecentFactorPerformance(
  signal?: AbortSignal,
): Promise<OperationResult<RecentPortfolioFactorHistory>> {
  const endpoint = '/api/dashboard/factor-performance';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: RecentPortfolioFactorHistory = (await response.json()) as RecentPortfolioFactorHistory;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
