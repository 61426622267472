import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import {
  type CategoryGroupEnum,
  type CategoryGroupOption,
  type CurrencyEnum,
  type Fund,
  type InvestmentAssetTypeEnum,
  type ComputedInvestmentResidual,
  AssetTypeEnum,
  getInvestmentResidual,
} from 'venn-api';
import { isEmpty, map, values } from 'lodash';
import { getAppTitle, Notifications, NotificationType, ShimmerBlock, CATEGORIES_FAQ_HREF } from 'venn-ui-kit';
import TextRow from '../TextRow';
import SelectorRow from '../SelectorRow';
import type { UseMetaDataReturn } from '../../meta-data';
import UserContext from '../../contexts/user-context';
import CurrencyConversionWarning from './CurrencyConversionWarning';
import { FundUtils, useHasFF } from 'venn-utils';
import { StyledMetaDataFields } from '../styles';
import ResidualForecastRow from './ResidualForecastRow';
import YieldRow from './YieldRow';

interface InvestmentInformationProps {
  fund: Fund;
  onFundUpdated: () => void;
  useMetaDataReturn: UseMetaDataReturn;
  canEditForecasts: boolean;
}

const InvestmentInformation = ({
  fund,
  onFundUpdated,
  useMetaDataReturn,
  canEditForecasts,
}: InvestmentInformationProps) => {
  const { profileSettings, hasPermission } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);
  const {
    metaData,
    metaDataError,
    currencyOptions,
    categoryAssetTypes,
    categoryGroups,
    categoryOptions,
    unsavedMetadata,
    updateUnsavedMetadata,
    saveUnsavedMetadata,
  } = useMetaDataReturn;

  useEffect(() => {
    if (metaDataError) {
      Notifications.notify('Failed to update investment information.', NotificationType.ERROR);
    }
  }, [metaDataError]);

  const canSave = !isEmpty(values(unsavedMetadata).filter((value) => value !== undefined));

  const handleSave = useCallback(() => {
    if (canSave) {
      saveUnsavedMetadata(() => {
        onFundUpdated();
        Notifications.notify('Investment information updated!', NotificationType.SUCCESS);
      });
    }
  }, [canSave, onFundUpdated, saveUnsavedMetadata]);

  useEffect(() => {
    // autosave every time one of the fields controlled by dropdowns changes
    // autosaving for input fields happens through onBlur
    if (unsavedMetadata.currency || unsavedMetadata.categoryAssetType || unsavedMetadata.categoryGroup !== undefined) {
      handleSave();
    }
    // onSave changes every time saveUnsavedMetadata changes which changes when multiple things
    // change like metadata and unsaved metadata -- so we don't want to make it a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedMetadata.categoryAssetType, unsavedMetadata.categoryGroup, unsavedMetadata.currency]);

  // If `unsavedMetadata.categoryGroup === null`, it's a valid group selection that we should honour.
  const selectedInvestmentCategory =
    unsavedMetadata.categoryGroup !== undefined ? unsavedMetadata.categoryGroup : metaData?.categoryGroup;

  const updateMetadataField = (field: 'name' | 'symbol' | 'currency') => (value: string | { value: CurrencyEnum }) => {
    const newValue = typeof value === 'string' ? value : value.value;
    updateUnsavedMetadata({ [field]: newValue === metaData?.[field] ? undefined : newValue });
  };

  const updateAssetType = ({ value }: { value: InvestmentAssetTypeEnum }) => {
    const hasModifiedAssetType = value !== metaData?.categoryAssetType?.key;
    updateUnsavedMetadata({
      categoryAssetType: hasModifiedAssetType ? categoryAssetTypes.find((option) => option.key === value) : undefined,
      categoryGroup: hasModifiedAssetType ? null : undefined,
    });
  };

  const updateCategory = ({ value }: { value: CategoryGroupEnum }) => {
    updateUnsavedMetadata({
      categoryGroup:
        value === metaData?.categoryGroup?.key ? undefined : categoryGroups.find((option) => option.key === value),
    });
  };

  const isEditable = Boolean(fund?.userEditable) && hasPermission('UPLOAD_RETURNS');
  const isCustodian = fund?.investmentSource === 'CUSTODIAN';
  const showDisabledStyling = !isEditable && !isCustodian;
  const currentMetaData = {
    symbol: unsavedMetadata.symbol ?? metaData?.symbol,
    currency: unsavedMetadata.currency || metaData?.currency,
    dataSource: unsavedMetadata.dataSource || metaData?.dataSource,
    live: unsavedMetadata.live || metaData?.live,
    accountNumber: unsavedMetadata.accountNumber || metaData?.accountNumber,
    categoryAssetType: unsavedMetadata.categoryAssetType || metaData?.categoryAssetType,
    categoryGroup: selectedInvestmentCategory,
  };

  const selectedCategoryGroupKey = currentMetaData?.categoryGroup?.key;

  const nonCategoryAssetTypeLabel = FundUtils.getAssetTypeName(fund?.assetType);
  const investmentCategoryLabel = fund?.category ? categoryOptions[fund.category] : '--';
  const selectedAssetType = currentMetaData?.categoryAssetType?.key;

  const filteredCategoryOptions = categoryGroups
    .filter(({ assetType }: CategoryGroupOption) => assetType === selectedAssetType)
    .map((categoryGroup) => ({
      label: categoryGroup.name,
      value: categoryGroup.key,
      icon: undefined,
    }));

  const isCurrencyDifferentFromWorkspace = profileSettings?.organization.currency !== currentMetaData.currency;

  /* Locally keep track and update the investment residual */
  const [residualForecast, setResidualForecast] = useState<ComputedInvestmentResidual>();
  const refetchResidualForecasts = useCallback(async () => {
    const fetchedResidualForecast = (await getInvestmentResidual(fund.id)).content;
    setResidualForecast(fetchedResidualForecast);
  }, [fund.id]);
  useEffect(() => {
    refetchResidualForecasts();
  }, [refetchResidualForecasts]);

  /* Only refetch the forecast if it was updated by the modal */
  const onResidualForecastUpdated = useCallback(
    (targetId?: string) => {
      targetId && targetId === fund.id && refetchResidualForecasts();
    },
    [fund.id, refetchResidualForecasts],
  );

  const hasTestHideCategoryFF = useHasFF('test_hide_category_ff');
  const hasCategoriesSearchFF = useHasFF('categories_search_ff');

  const hasYieldIncomeFF = useHasFF('yield_income_ff');
  const isYieldEditable = hasPermission('EDIT_ESTIMATED_YIELD');
  const isCompositeBenchmark = metaData?.assetType === AssetTypeEnum.BENCHMARK;

  return (
    <StyledMetaDataFields>
      {isCustodian ? (
        <>
          {currentMetaData.dataSource && (
            <TextRow
              label="Data Source"
              isEditable={false}
              value={currentMetaData.dataSource}
              icon="exchange"
              iconColor={currentMetaData.live ? Colors.Gold : Colors.Grey}
              className="qa-data-source"
              showDisabledStyling={showDisabledStyling}
              onBlur={handleSave}
            />
          )}
          {currentMetaData.accountNumber && (
            <TextRow
              className="qa-account-number"
              label="Account Number"
              isEditable={false}
              value={currentMetaData.accountNumber}
              showDisabledStyling={showDisabledStyling}
              onBlur={handleSave}
            />
          )}
        </>
      ) : (
        <TextRow
          className="qa-ticker"
          label="Ticker"
          placeholder="e.g. AAPL"
          isEditable={isEditable}
          value={currentMetaData.symbol}
          onChange={updateMetadataField('symbol')}
          showDisabledStyling={showDisabledStyling}
          disabledTooltip={`Ticker information can not be edited for ${getAppTitle()}-uploaded data"`}
          onBlur={handleSave}
        />
      )}
      <SelectorRow<CurrencyEnum>
        label="Currency"
        isEditable={isEditable}
        value={currentMetaData.currency}
        options={map(currencyOptions, (value, key) => ({
          label: value,
          value: key as CurrencyEnum,
        }))}
        onSelect={updateMetadataField('currency')}
        className="qa-currency"
        showDisabledStyling={showDisabledStyling}
        disabledTooltip={`Currency information can not be edited for ${getAppTitle()}-uploaded data`}
        labelRightContent={isCurrencyDifferentFromWorkspace ? <CurrencyConversionWarning /> : undefined}
      />
      {hasCategoriesSearchFF && fund?.morningstarCategory && (
        <TextRow
          className="qa-asset-type"
          label="Morningstar Category"
          isEditable={false}
          value={fund.morningstarCategory}
          showDisabledStyling={showDisabledStyling}
          onBlur={handleSave}
        />
      )}
      {hasTestHideCategoryFF && (
        <>
          <TextRow
            className="qa-asset-type"
            label="Asset Type"
            isEditable={false}
            value={nonCategoryAssetTypeLabel}
            showDisabledStyling={showDisabledStyling}
            onBlur={handleSave}
          />
          <TextRow
            className="qa-investment-category"
            label="Category"
            isEditable={false}
            value={investmentCategoryLabel}
            showDisabledStyling={showDisabledStyling}
            onBlur={handleSave}
          />
        </>
      )}
      {!hasTestHideCategoryFF && (
        <>
          <SelectorRow<InvestmentAssetTypeEnum>
            label="Asset Type"
            isEditable={isEditable || isCustodian}
            value={selectedAssetType}
            isPredicted={false}
            options={categoryAssetTypes.map((categoryAssetType) => ({
              label: categoryAssetType.name,
              value: categoryAssetType.key,
            }))}
            onSelect={updateAssetType}
            className="qa-asset-type"
            showDisabledStyling={showDisabledStyling}
            disabledTooltip={`Asset Type information can not be edited for ${getAppTitle()}-uploaded data`}
          />
          <SelectorRow<CategoryGroupEnum>
            label="Investment Category"
            isEditable={isEditable || isCustodian}
            value={selectedCategoryGroupKey}
            isPredicted={false}
            options={filteredCategoryOptions}
            onSelect={updateCategory}
            disabledMessage={
              !isEditable || selectedAssetType ? undefined : 'Select Asset Type before setting Investment Category'
            }
            className="qa-investment-category"
            info="Investment categories describe the style of investing this fund may employ. Click for more information"
            link={CATEGORIES_FAQ_HREF}
            showDisabledStyling={showDisabledStyling}
            disabledTooltip={`Investment Category information can not be edited for ${getAppTitle()}-uploaded data`}
            loading={false}
          />
        </>
      )}
      <Suspense fallback={<ShimmerBlock />}>
        {residualForecast && (
          <ResidualForecastRow
            canEditForecasts={canEditForecasts}
            onResidualForecastUpdated={onResidualForecastUpdated}
            residualForecast={residualForecast}
          />
        )}
      </Suspense>

      <Suspense fallback={<ShimmerBlock height={35} />}>
        {hasYieldIncomeFF && !isCompositeBenchmark && <YieldRow fundId={fund.id} canEditYield={isYieldEditable} />}
      </Suspense>
    </StyledMetaDataFields>
  );
};

export default InvestmentInformation;
