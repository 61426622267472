/*
 * Venn Platform API 1.0.0 Operations for "clientintegration"
 * Automatically generated file - do not modify!
 */

import type {
  AddeparAttributeValue,
  AddeparDataPreview,
  AddeparOption,
  AddeparOptionTypeEnum,
  AddeparViewConfigV2Request,
  AddeparViewConfigurationV2,
  ClientIntegration,
  IntegrationSetupConfig,
  IntegrationTypeEnum,
  SetAddeparReturnAttributeRequest,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Delete a specific integration */
export async function deleteIntegration(
  integrationUuid: string,
  integrationName: IntegrationTypeEnum,
  deleteData?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/clientintegration/${encodeURIComponent(integrationName)}/${encodeURIComponent(integrationUuid)}${buildQuery({ deleteData })}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get Addepar options */
export async function getAddeparOptions(
  firmId: string,
  type: AddeparOptionTypeEnum,
  forceRefresh?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<AddeparOption[]>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/options${buildQuery({ type, forceRefresh })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparOption[] = (await response.json()) as AddeparOption[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get Addepar return attribute */
export async function getAddeparReturnAttribute(
  firmId: string,
  signal?: AbortSignal,
): Promise<OperationResult<AddeparOption>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/config/return`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparOption = (await response.json()) as AddeparOption;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of all Addepar view configurations for the given firm */
export async function getAddeparViewConfigurationList(
  firmId: string,
  signal?: AbortSignal,
): Promise<OperationResult<AddeparViewConfigurationV2[]>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/config/views`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparViewConfigurationV2[] = (await response.json()) as AddeparViewConfigurationV2[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of values for the attribute */
export async function getAddeparAttributeValues(
  firmId: string,
  attributeKey: string,
  forceRefresh?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<AddeparAttributeValue[]>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/attribute/${encodeURIComponent(attributeKey)}${buildQuery({ forceRefresh })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparAttributeValue[] = (await response.json()) as AddeparAttributeValue[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all integrations of the Authenticated User for the given integration, sorted by active, added */
export async function getAllIntegrations(
  integrationName: IntegrationTypeEnum,
  signal?: AbortSignal,
): Promise<OperationResult<ClientIntegration[]>> {
  const endpoint = `/api/clientintegration/${encodeURIComponent(integrationName)}/status`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ClientIntegration[] = (await response.json()) as ClientIntegration[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return portfolio preview for the given firm based on the provided view configuration */
export async function getAddeparDataPreview(
  firmId: string,
  body: Partial<AddeparViewConfigurationV2>,
  forceRefresh?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<AddeparDataPreview>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/config/views/preview${buildQuery({ forceRefresh })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparDataPreview = (await response.json()) as AddeparDataPreview;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set Addepar return attribute */
export async function setAddeparReturnAttribute(
  firmId: string,
  body?: Partial<SetAddeparReturnAttributeRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/config/return`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set up an integration via OAuth2 */
export async function getIntegrationSetupConfig(
  integrationName: IntegrationTypeEnum,
  signal?: AbortSignal,
): Promise<OperationResult<IntegrationSetupConfig>> {
  const endpoint = `/api/clientintegration/${encodeURIComponent(integrationName)}/setup`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: IntegrationSetupConfig = (await response.json()) as IntegrationSetupConfig;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set up an integration via OAuth2 */
export async function oAuth2(
  integrationName: IntegrationTypeEnum,
  code?: string,
  signal?: AbortSignal,
): Promise<OperationResult<ClientIntegration>> {
  const endpoint = `/api/clientintegration/${encodeURIComponent(integrationName)}/setup/oauth2${buildQuery({ code })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ClientIntegration = (await response.json()) as ClientIntegration;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the list of all Addepar view configurations for the given firm */
export async function updateAddeparViewConfigurationList(
  firmId: string,
  body: AddeparViewConfigV2Request[],
  signal?: AbortSignal,
): Promise<OperationResult<AddeparViewConfigurationV2[]>> {
  const endpoint = `/api/clientintegration/addepar/${encodeURIComponent(firmId)}/config/views`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AddeparViewConfigurationV2[] = (await response.json()) as AddeparViewConfigurationV2[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
