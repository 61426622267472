/*
 * Venn Platform API 1.0.0 Operations for "timeseries-v2"
 * Automatically generated file - do not modify!
 */

import type {
  AutoproxyResponse,
  FileGridPreview,
  FileMapping,
  FileMetadata,
  FileOptions,
  FilePreview,
  MappedFund,
  MappingAndErrors,
  ParsedFileUploadResponse,
  SeriesCell,
  TimeSeriesParsedFile,
  TimeSeriesPersistResult,
  UploadTypeEnum,
  UserGridSelection,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Download a file by identifier */
export async function adminGetFile(fileId: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/download`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get metadata differences between what the server computes and what the user actually mapped */
export async function getMetadataErrors(
  page?: number,
  pageSize?: number,
  signal?: AbortSignal,
): Promise<OperationResult<ParsedFileUploadResponse[]>> {
  const endpoint = `/api/timeseries/file/v2/metadata/errors${buildQuery({ page, pageSize })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: ParsedFileUploadResponse[] = (await response.json()) as ParsedFileUploadResponse[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the metadata values for data uploader */
export async function getUploadMetadata(signal?: AbortSignal): Promise<OperationResult<FileOptions>> {
  const endpoint = '/api/timeseries/file/v2/options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FileOptions = (await response.json()) as FileOptions;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the parsing associated with a given document */
export async function getParsedFileForDocument(
  documentUuid: string,
  version?: number,
  signal?: AbortSignal,
): Promise<OperationResult<TimeSeriesParsedFile>> {
  const endpoint = `/api/timeseries/file/v2/document/${encodeURIComponent(documentUuid)}/parse}${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: TimeSeriesParsedFile = (await response.json()) as TimeSeriesParsedFile;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Parse a upload nav file */
export async function uploadNavsParser(
  uploadType?: UploadTypeEnum,
  body?: any,
  signal?: AbortSignal,
): Promise<OperationResult<MappedFund[]>> {
  const endpoint = `/api/timeseries/file/v2/navs/parse${buildQuery({ uploadType })}`;
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MappedFund[] = (await response.json()) as MappedFund[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Persist the time series values to the DB */
export async function persistTimeSeries(
  fileId: number,
  uploadType?: UploadTypeEnum,
  signal?: AbortSignal,
): Promise<OperationResult<TimeSeriesPersistResult>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/persist${buildQuery({ uploadType })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TimeSeriesPersistResult = (await response.json()) as TimeSeriesPersistResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Preview the content of an uploaded file */
export async function getFileGridPreview(
  fileId: number,
  signal?: AbortSignal,
): Promise<OperationResult<FileGridPreview>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/raw`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FileGridPreview = (await response.json()) as FileGridPreview;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Preview the content of an uploaded file */
export async function getFilePreview(
  fileId: number,
  rows?: number,
  signal?: AbortSignal,
): Promise<OperationResult<FilePreview>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/preview${buildQuery({ rows })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FilePreview = (await response.json()) as FilePreview;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Preview the mapping of an uploaded file */
export async function getFileMapping(fileId: number, signal?: AbortSignal): Promise<OperationResult<FileMapping>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/mapping`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FileMapping = (await response.json()) as FileMapping;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove the underlying uploaded file */
export async function removeFile(fileId: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve listing of files uploaded on the platform */
export async function listFiles(
  page?: number,
  pageSize?: number,
  signal?: AbortSignal,
): Promise<OperationResult<FileMetadata[]>> {
  const endpoint = `/api/timeseries/file/v2/list${buildQuery({ page, pageSize })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: FileMetadata[] = (await response.json()) as FileMetadata[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Return autoproxy suggestions for the given series */
export async function autoproxySeries(
  fileId: number,
  seriesId: string,
  extrapolate?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<AutoproxyResponse>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/${encodeURIComponent(seriesId)}/autoproxy${buildQuery({ extrapolate })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: AutoproxyResponse = (await response.json()) as AutoproxyResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the active grid structure for an uploaded file */
export async function updateStructure(
  fileId: number,
  body?: Partial<UserGridSelection>,
  signal?: AbortSignal,
): Promise<OperationResult<MappingAndErrors>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/structure`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: MappingAndErrors = (await response.json()) as MappingAndErrors;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the list of overriden values on top of uploaded file */
export async function correctData(
  fileId: number,
  body?: SeriesCell[],
  signal?: AbortSignal,
): Promise<OperationResult<MappingAndErrors>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/corrections`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MappingAndErrors = (await response.json()) as MappingAndErrors;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the mapping for an uploaded file */
export async function updateMapping(
  fileId: number,
  body?: Partial<FileMapping>,
  signal?: AbortSignal,
): Promise<OperationResult<MappingAndErrors>> {
  const endpoint = `/api/timeseries/file/v2/${encodeURIComponent(fileId)}/mapping`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MappingAndErrors = (await response.json()) as MappingAndErrors;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Upload a file to be parsed as part of uploader */
export async function parseDocument(
  documentUuid: string,
  documentVersion?: number,
  signal?: AbortSignal,
): Promise<OperationResult<TimeSeriesParsedFile>> {
  const endpoint = `/api/timeseries/file/v2/document/${encodeURIComponent(documentUuid)}/parse${buildQuery({ documentVersion })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TimeSeriesParsedFile = (await response.json()) as TimeSeriesParsedFile;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Upload a file to be parsed as part of uploader */
export async function uploadTimeSeriesFile(
  uploadType?: UploadTypeEnum,
  body?: any,
  signal?: AbortSignal,
): Promise<OperationResult<MappingAndErrors>> {
  const endpoint = `/api/timeseries/file/v2${buildQuery({ uploadType })}`;
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MappingAndErrors = (await response.json()) as MappingAndErrors;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
