/*
 * Venn Platform API 1.0.0 Operations for "compositebenchmarks"
 * Automatically generated file - do not modify!
 */

import type { CompositeBenchmark } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Create composite benchmark */
export async function createCompositeBenchmark(
  body?: Partial<CompositeBenchmark>,
  signal?: AbortSignal,
): Promise<OperationResult<CompositeBenchmark>> {
  const endpoint = '/api/compositebenchmarks';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CompositeBenchmark = (await response.json()) as CompositeBenchmark;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete composite benchmark */
export async function deleteCompositeBenchmark(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/compositebenchmarks/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get composite benchmark */
export async function getCompositeBenchmark(
  id: number,
  signal?: AbortSignal,
): Promise<OperationResult<CompositeBenchmark>> {
  const endpoint = `/api/compositebenchmarks/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CompositeBenchmark = (await response.json()) as CompositeBenchmark;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get composite benchmark by fund Id */
export async function getCompositeBenchmarkByFundId(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<CompositeBenchmark>> {
  const endpoint = `/api/compositebenchmarks/fundId/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CompositeBenchmark = (await response.json()) as CompositeBenchmark;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get composite benchmarks */
export async function getCompositeBenchmarks(signal?: AbortSignal): Promise<OperationResult<CompositeBenchmark[]>> {
  const endpoint = '/api/compositebenchmarks';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: CompositeBenchmark[] = (await response.json()) as CompositeBenchmark[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update composite benchmark */
export async function updateCompositeBenchmark(
  id: number,
  body?: Partial<CompositeBenchmark>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/compositebenchmarks/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
