/*
 * Venn Platform API 1.0.0 Operations for "optimization"
 * Automatically generated file - do not modify!
 */

import type { InvestmentFactorForecast, NamedEntityInteger } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError, retryGeneratedFetch } from '../../utils';

/** Forecast investment returns given the current factor forecast */
export async function forecastInvestmentReturns(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: InvestmentFactorForecast }>> {
  const endpoint = '/api/optimization/investment/forecast';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: InvestmentFactorForecast } = (await response.json()) as {
      [key: string]: InvestmentFactorForecast;
    };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get optimization configuration targets */
export async function getOptimizationTargets(signal?: AbortSignal): Promise<OperationResult<NamedEntityInteger[]>> {
  const endpoint = '/api/optimization/target';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await retryGeneratedFetch(() => fetch(endpoint, requestInit));
  if (response.status >= 200 && response.status < 400) {
    const content: NamedEntityInteger[] = (await response.json()) as NamedEntityInteger[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
